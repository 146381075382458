.timelineHolder{
    .rediculous-timeline{
        position: relative;
        overflow: visible;
        .swiper-wrapper{
            position: relative;
            align-items: center;
            &::before{
                position: absolute;
                content: '';
                left: -9999px;
                right: -9999px;
                top: 50%;
                height: 1px;
                background: #679CF6;
            }
        }
        .swiper-slide-active{
            .timeline-slide-widget{
                background: #679CF6;
                color: #fff;
            }
        }
    }
    .nav-btns{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        margin-top: 5.5625rem;
        .btn{
            padding: 0;
            color: #679CF6;
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0 0.9375rem;
            &:focus,
            &:active{
                outline: none;
                box-shadow: none;
            }
        }
    }
}