.latest-newsfeeds{
    .link-holder{
        text-decoration: none;
        color: #31394D;
        .news{
            cursor: pointer;
            margin: 0 0 3.125rem;
            border-radius: 8px;
            box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
            -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
            .content{
                padding: .75rem;
                p{
                    margin: 0;
                    font-size: 0.9em;
                    margin: 1em 0em;
                }
                h1{
                    font-size: 1rem;
                    margin: 0;
                    // height: 52px;
                    overflow: hidden;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    @media only screen and (min-width: 767px){
                        height: 52px;
                        font-size: 0.875rem;
                    }
                }
                .authur-name{
                    font-size: 0.75rem;
                }
                img{
                    width: 100%;
                    border-radius: 6px;
                    aspect-ratio: 2/1;
                    object-fit: cover;
                }
                .authorImg{
                    width: 1.75em;
                    height: 1.75em;
                    border-radius: 50%;
                    object-fit: cover;
                }
                div img{
                    width: 1em;
                    aspect-ratio: none;
                    object-fit: none;
                }
                .btn{
                    padding: 0;
                    color: #679CF6;
                }
                time{
                    color: rgba(49, 57, 77, 0.5);
                    display: block;
                    margin: 17px 0 9px;
                    font-size: .875rem;
                }
            }
            hr{
                margin: 0;
            }
            a{
                color: #31394D;
                text-decoration: none;
            }
        }
    }
}

