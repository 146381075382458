.flyover-wrapper {
    font-size: calc((0.6060*100vw)/100);
}

/* #wrapper {
    overflow: hidden;
    margin: 0;
    font-size: 1.375em;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
} */

#wrapper {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 0 10px;
}

.anim-container {
    /* max-width: 92.82em; */
    /* max-width: 1080px; */
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    aspect-ratio: 165 / 71;
    /* height: 465px; */
    background-position: scroll;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    perspective: 19.765em;
    /* perspective-origin: 50% 11%; */
    perspective-origin: 50% 11%;
    position: relative;
}
.anim-container .loading-flyover{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8em;
    transform: translate(-50%, -50%);
    transform-origin: center;
    animation-name: loading;
    animation-duration: 3s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}
.anim-container .loading-flyover img{
    width: 100%;
    display: block;
    height: auto;
    margin: 0;
}
.fullscreen-btn-container{
    /* max-width: 92.72em; */
    /* margin: -3.4em auto 0; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: .1em;
    right: .1em;
    bottom: -5em;
    z-index: 2;
    padding: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 1.125em;
    /* bottom: -5em; */
    transition: all .5s ease;
    z-index: 99;
}
.anim-container:hover .fullscreen-btn-container{bottom: 0;}
.fullscreen-btn-container input[type="range"]{width: 88%;}
.anim-container:fullscreen .fullscreen-btn-container input[type="range"]{width: 88%;}
.anim-container .color-labels{
    position: absolute;
    left: 0.859em;
    top: 0.859em;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.2em;
    color: #fff;
    z-index: 1;
}
.anim-container:fullscreen .color-labels{
    left: 1.7em;
    top: 1.7em;
    font-size: 1.6em;
}
.anim-container .color-labels li{
    margin: 0;
    position: relative;
    padding: 0 0 0 1.28em;
}
.anim-container:fullscreen .color-labels li{
    padding: 0 0 0 2em;
    margin: 0 0 .25em;
}
.anim-container .color-labels li:before{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.859em;
    height: 0.859em;
    content: '';
    border-radius: 2px;
    overflow: hidden;
}
.anim-container:fullscreen .color-labels li:before{
    width: 1.2em;
    height: 1.2em;
}
.anim-container .color-labels li.lane1:before{background: #ee1f27;}
.anim-container .color-labels li.lane2:before{background: #f16623;}
.anim-container .color-labels li.lane3:before{background: #fede17;}
.anim-container .color-labels li.lane4:before{background: #01a14b;}
.anim-container .color-labels li.lane5:before{background: #2e58ac;}
.anim-container .logo-image{
    position: absolute;
    right: 1.7em;
    top: 1.7em;
    max-width: 17em;
    z-index: 1;
    display: none;
}
/* .anim-container .media-btn{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 7.875em;
    height: 7.875em;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);
    z-index: 99;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
}
.anim-container .media-btn img{
    display: block;
    max-width: 40%;
    margin: 0 auto;
    height: auto;
}
.anim-container .media-btn img.play{display: none;}
.anim-container.pause .media-btn img.play{display: block;}
.anim-container.pause .media-btn img.pause{display: none;}
.anim-container:fullscreen .logo-image{display: block;}
.anim-container:hover .media-btn{
    opacity: 1;
    visibility: visible;
} */
.anim-content {
    width: 25.781em;
    animation-duration: 10s;
    /* animation-iteration-count: infinite; */
    animation-timing-function: linear;
    animation-fill-mode: both;
    /* animation-play-state: paused; */
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 1s ease;
}
.pause .anim-content{animation-play-state: paused;}

.loop .anim-content{
    /* animation-iteration-count: infinite; */
}


.anim-content .content-holder {
    margin: 0;
    position: relative;
    background: #fff;
    color: #000;
    text-align: center;
    padding: 0.86em;
    border-radius: 0.86em;
    /* font-size: 150%; */
    line-height: 1.3;
    font-weight: 700;
}

.anim-content .content-holder:before {
    position: absolute;
    content: '';
    left: 50%;
    top: 100%;
    border: 1.1719em solid transparent;
    border-top: 1.1719em solid #fff;
    transform: translateX(-50%);
    margin: -0.1719em 0 0;
}

.anim-content .content-holder .image-holder {
    overflow: hidden;
    margin: 0;
    padding: 0;
    border-radius: 0.429em;
}

.anim-content .content-holder .image-holder img {
    display: block;
    width: 100%;
    height: 16em;
    margin: 0;
    object-fit: cover;
}

.anim-content .content-holder div.outer {
    height: 8em;
    word-wrap: break-word;
}
.anim-content .content-holder div.content-frame strong{
    display: block;
    font-size: 2.4em;
    color: #2e58ac;
    padding: 1em 0 0.5em;
    margin: 0;
}

.anim-content .content-holder div.outer p {
    padding: 0 0 1em;
    margin: 0;
}

.anim-content .circle {
    width: 5.15em;
    height: 5.15em;
    background: #2e58ac;
    border-radius: 50%;
    border-bottom: 0.429em solid rgba(0, 0, 0, 0.5);
    margin: -0.86em auto 0;
    transform: rotateX(-60deg);
    position: relative;
    z-index: -1;
    box-shadow: 0 0 1.718em 1.718em rgba(255, 255, 255, 0.5);
}

.btn-fullscreen {
    /* position: absolute; */
    /* right: 0.17em; */
    /* bottom: 0.17em; */
    width: 3em;
    height: 3em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
    border-radius: 0;
    border: none;
    background: #1D70B7;
    padding: .7em;
    box-shadow: 0 0 .5em rgba(255, 255, 255, 0.5);
    cursor: pointer;
    position: relative;
}

.btn-fullscreen img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    height: auto;
}

.btn-fullscreen span {
    position: absolute;
    top: 50%;
    right: 100%;
    z-index: 1;
    background: #fff;
    font-size: 0.8em;
    width: 6em;
    padding: 0.5em 1em;
    border-radius: 0.3em;
    margin: 0 1em 0 0;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
}

.btn-fullscreen span:before {
    position: absolute;
    left: 99%;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    border: 0.423em solid transparent;
    border-left: 0.601em solid #fff;
}

.btn-fullscreen:hover span {
    opacity: 1;
    visibility: visible;
}

.anim-content.lane5 {
    transform: translate3d(52.4em, -12em, -56.71em);
    animation-name: lane-blue;
}
.anim-content.lane4 {
    /* transform: translate3d(42em, -12em, -56.71em); */
    transform: translate3d(306%, -12em, -56.71em);
    animation-name: lane-green;
    animation-delay: 1s;
}
.anim-content.lane4 .circle{background: #01a14b;}
.anim-content.lane3{
    /* transform: translate3d(33.5em, -12em, -56.71em); */
    transform: translate3d(250%, -12em, -56.71em);
    animation-name: lane-yellow;
    animation-delay: 2s;
}
.anim-content.lane3 .circle{background: #fede17;}
.anim-content.lane2{
    /* transform: translate3d(25.4em, -12em, -56.71em); */
    transform: translate3d(228%, -12em, -56.71em);
    animation-name: lane-orange;
    animation-delay: 1.5s;
}
.anim-content.lane2 .circle{background: #f16623;}
.anim-content.lane1{
    /* transform: translate3d(14.5em, -12em, -56.71em); */
    transform: translate3d(170%, -12em, -56.71em);
    animation-name: lane-red;
    animation-delay: 4s;
}
.anim-content.lane1 .circle{background: #ee1f27;}

.anim-container:fullscreen{
    max-width: 100vw;
    aspect-ratio: auto;
    height: 100vh;
    background-size: cover;
    perspective: 22.60vw;
    perspective-origin: 50% 11%;
}
.anim-container:fullscreen #wrapper{padding: 0;}
.anim-container:fullscreen .anim-content{
    width: 18vw;
    animation-fill-mode: both;
}

.anim-container:fullscreen .anim-content .content-holder{
    padding: 0.86vw;
    border-radius: 0.86vw;
}
.anim-container:fullscreen .anim-content .content-holder:before{
    border: 1.1719vw solid transparent;
    border-top: 1.1719vw solid #fff;
    margin: -0.1719vw 0 0;
}
.anim-container:fullscreen .anim-content .content-holder .image-holder{
    border-radius: 0.86vw;
}
.anim-container:fullscreen .anim-content .content-holder .image-holder img{
    height: 10vw;
}
.anim-container:fullscreen .anim-content .circle{
    width: 5.15vw;
    height: 5.15vw;
    border-bottom: 0.429vw solid rgba(0, 0, 0, 0.5);
    margin: -0.86vw auto 0;
    box-shadow: 0 0 1.718vw 1.718vw rgba(255, 255, 255, 0.5);
}

.anim-container:fullscreen .anim-content.lane1{
    animation-name: lane-red-full;
    transform: translate3d(39.5em, 0, -56.71em);
}
.anim-container:fullscreen .anim-content.lane2{
    animation-name: lane-orange-full;
    transform: translate3d(55em, 0, -56.71em);
}
.anim-container:fullscreen .anim-content.lane3{
    animation-name: lane-yellow-full;
    transform: translate3d(67.8em, 0, -56.71em);
}
.anim-container:fullscreen .anim-content.lane4{
    animation-name: lane-green-full;
    transform: translate3d(80.6em, 0, -56.71em);
}
.anim-container:fullscreen .anim-content.lane5{
    animation-name: lane-blue-full;
    transform: translate3d(68.2vw,7.4vw,-70vw);
}
.warning-msg{display: none;}
.anim-container.pause .btn-fullscreen img.pause-icon,
.anim-container .btn-fullscreen img.play-icon{display: none;}
.anim-container.pause .btn-fullscreen img.play-icon{display: block;}
.overlay-layer{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    opacity: 0;
}
.tags-toggler{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999;
    background: none;
    color: #fff;
    font-size: 1.8rem;
    border: none;
    box-shadow: none;
}


/* animation */
/* @keyframes lane-blue {
    0% {
        -webkit-transform: translate3d(52.4em, -12em, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10%{
        z-index: 1
    }

    20% {
        opacity: 0.1;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }

    40% {
        opacity: 1;
        z-index: 4;
    }
    50% {
        z-index: 5;
    }
    60% {
        z-index: 6;
    }

    70% {
        z-index:7;
    }
    80% {
        z-index:8;
    }
    90% {
        z-index:9;
    }

    100% {
        -webkit-transform: translate3d(80.4em, 40.72em, 0);
        opacity: 1;
        z-index: 10;
    }
} */
@keyframes lane-blue {
    0% {
        -webkit-transform: translate3d(360%, -12em, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10%{
        z-index: 1
    }

    20% {
        opacity: 0.1;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }

    40% {
        opacity: 1;
        z-index: 4;
    }
    50% {
        z-index: 5;
    }
    60% {
        z-index: 6;
    }

    70% {
        z-index:7;
    }
    80% {
        z-index:8;
    }
    90% {
        z-index:9;
    }

    100% {
        -webkit-transform: translate3d(525%, 70.72em, 0);
        opacity: 1;
        z-index: 10;
    }
}
/* @keyframes lane-green {
    0% {
        -webkit-transform: translate3d(42em, -12em, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10%{
        z-index: 1
    }

    20% {
        opacity: 0.1;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }

    40% {
        opacity: 1;
        z-index: 4;
    }
    50% {
        z-index: 5;
    }
    60% {
        z-index: 6;
    }

    70% {
        z-index:7;
    }
    80% {
        z-index:8;
    }
    90% {
        z-index:9;
    }

    100% {
        -webkit-transform: translate3d(58.6em, 40.72em, 0);
        opacity: 1;
        z-index: 10;
    }
} */
@keyframes lane-green {
    0% {
        -webkit-transform: translate3d(306%, -12em, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10%{
        z-index: 1
    }

    20% {
        opacity: 0.1;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }

    40% {
        opacity: 1;
        z-index: 4;
    }
    50% {
        z-index: 5;
    }
    60% {
        z-index: 6;
    }

    70% {
        z-index:7;
    }
    80% {
        z-index:8;
    }
    90% {
        z-index:9;
    }

    100% {
        -webkit-transform: translate3d(399%, 70.72em, 0);
        opacity: 1;
        z-index: 10;
    }
}
/* @keyframes lane-yellow {
    0% {
        -webkit-transform: translate3d(33.5em, -12em, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10%{
        z-index: 1
    }

    20% {
        opacity: 0.1;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }

    40% {
        opacity: 1;
        z-index: 4;
    }
    50% {
        z-index: 5;
    }
    60% {
        z-index: 6;
    }

    70% {
        z-index:7;
    }
    80% {
        z-index:8;
    }
    90% {
        z-index:9;
    }

    100% {
        -webkit-transform: translate3d(33.5em, 40.72em, 0);
        opacity: 1;
        z-index: 10;
    }
} */

@keyframes lane-yellow {
    0% {
        -webkit-transform: translate3d(267%, -12em, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10%{
        z-index: 1
    }

    20% {
        opacity: 0.1;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }

    40% {
        opacity: 1;
        z-index: 4;
    }
    50% {
        z-index: 5;
    }
    60% {
        z-index: 6;
    }

    70% {
        z-index:7;
    }
    80% {
        z-index:8;
    }
    90% {
        z-index:9;
    }

    100% {
        -webkit-transform: translate3d(267%, 70.72em, 0);
        opacity: 1;
        z-index: 10;
    }
}

/* @keyframes lane-orange {
    0% {
        -webkit-transform: translate3d(25.4em, -12em, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10%{
        z-index: 1
    }

    20% {
        opacity: 0.1;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }

    40% {
        opacity: 1;
        z-index: 4;
    }
    50% {
        z-index: 5;
    }
    60% {
        z-index: 6;
    }

    70% {
        z-index:7;
    }
    80% {
        z-index:8;
    }
    90% {
        z-index:9;
    }

    100% {
        -webkit-transform: translate3d(9.4em, 40.72em, 0);
        opacity: 1;
        z-index: 10;
    }
} */
@keyframes lane-orange {
    0% {
        -webkit-transform: translate3d(228%, -12em, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10%{
        z-index: 1
    }

    20% {
        opacity: 0.1;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }

    40% {
        opacity: 1;
        z-index: 4;
    }
    50% {
        z-index: 5;
    }
    60% {
        z-index: 6;
    }

    70% {
        z-index:7;
    }
    80% {
        z-index:8;
    }
    90% {
        z-index:9;
    }

    100% {
        -webkit-transform: translate3d(132%, 70.72em, 0);
        opacity: 1;
        z-index: 10;
    }
}

/* @keyframes lane-red {
    0% {
        -webkit-transform: translate3d(14.5em, -12em, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10%{
        z-index: 1
    }

    20% {
        opacity: 0.1;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }

    40% {
        opacity: 1;
        z-index: 4;
    }
    50% {
        z-index: 5;
    }
    60% {
        z-index: 6;
    }

    70% {
        z-index:7;
    }
    80% {
        z-index:8;
    }
    90% {
        z-index:9;
    }

    100% {
        -webkit-transform: translate3d(-14.8em, 40.72em, 0);
        opacity: 1;
        z-index: 10;
    }
} */
@keyframes lane-red {
    0% {
        -webkit-transform: translate3d(170%, -12em, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10%{
        z-index: 1
    }

    20% {
        opacity: 0.1;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }

    40% {
        opacity: 1;
        z-index: 4;
    }
    50% {
        z-index: 5;
    }
    60% {
        z-index: 6;
    }

    70% {
        z-index:7;
    }
    80% {
        z-index:8;
    }
    90% {
        z-index:9;
    }

    100% {
        -webkit-transform: translate3d(10%, 70.72em, 0);
        opacity: 1;
        z-index: 10;
    }
}
@keyframes lane-red-full {
    0% {
        -webkit-transform: translate3d(39.5em, 0, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10% {
        opacity: 0.1;
        z-index: 1;
    }

    20% {
        opacity: 0.2;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }
    40% {
        opacity: 1;
        z-index: 4;
    }
    50%{
        z-index: 5;
    }
    60%{
        z-index: 6;
    }
    70%{
        z-index: 7;
    }
    80%{
        z-index: 8;
    }
    90%{
        z-index: 9;
    }

    100% {
        -webkit-transform: translate3d(-20.8em, 100.72em, 0);
        opacity: 1;
        z-index: 10;
    }
}

@keyframes lane-orange-full {
    0% {
        -webkit-transform: translate3d(55em, 0, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10% {
        opacity: 0.1;
        z-index: 1;
    }

    20% {
        opacity: 0.2;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }
    40% {
        opacity: 1;
        z-index: 4;
    }
    50%{
        z-index: 5;
    }
    60%{
        z-index: 6;
    }
    70%{
        z-index: 7;
    }
    80%{
        z-index: 8;
    }
    90%{
        z-index: 9;
    }

    100% {
        -webkit-transform: translate3d(22.8em, 100.72em, 0);
        opacity: 1;
        z-index: 10;
    }
}

@keyframes lane-yellow-full {
    0% {
        -webkit-transform: translate3d(67.8em, 0, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10% {
        opacity: 0.1;
        z-index: 1;
    }

    20% {
        opacity: 0.2;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }
    40% {
        opacity: 1;
        z-index: 4;
    }
    50%{
        z-index: 5;
    }
    60%{
        z-index: 6;
    }
    70%{
        z-index: 7;
    }
    80%{
        z-index: 8;
    }
    90%{
        z-index: 9;
    }

    100% {
        -webkit-transform: translate3d(67.8em, 100.72em, 0);
        opacity: 1;
        z-index: 10;
    }
}

@keyframes lane-green-full {
    0% {
        -webkit-transform: translate3d(80.6em, 0, -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10% {
        opacity: 0.1;
        z-index: 1;
    }

    20% {
        opacity: 0.2;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }
    40% {
        opacity: 1;
        z-index: 4;
    }
    50%{
        z-index: 5;
    }
    60%{
        z-index: 6;
    }
    70%{
        z-index: 7;
    }
    80%{
        z-index: 8;
    }
    90%{
        z-index: 9;
    }

    100% {
        -webkit-transform: translate3d(113.6em, 100.72em, 0);
        opacity: 1;
        z-index: 10;
    }
}

@keyframes lane-blue-full {
    0% {
        -webkit-transform: translate3d(96em, 0 , -56.71em);
        opacity: 0;
        z-index: -1;
    }

    10% {
        opacity: 0.1;
        z-index: 1;
    }

    20% {
        opacity: 0.2;
        z-index: 2;
    }
    30%{
        z-index: 3;
    }
    40% {
        opacity: 1;
        z-index: 4;
    }
    50%{
        z-index: 5;
    }
    60%{
        z-index: 6;
    }
    70%{
        z-index: 7;
    }
    80%{
        z-index: 8;
    }
    90%{
        z-index: 9;
    }

    100% {
        -webkit-transform: translate3d(154.6em ,100.72em ,0);
        opacity: 1;
        z-index: 10;
    }
}

@keyframes loading {
    0%{
        filter: grayscale(0);
    }
    25%{
        filter: grayscale(100%);
    }
    50%{
        filter: grascale(0);
    }
    75%{
        filter: grascale(100%);
    }
    100%{
        filter: grascale(0);
    }
}
@media only screen and (max-width: 1400px){
    .fullscreen-btn-container input[type="range"]{width: 85%;}
}
@media only screen and (max-width: 1199px){
    .fullscreen-btn-container input[type="range"]{width: 80%;}
    .fullscreen-btn-container{font-size: 2em;}
}
@media only screen and (max-width: 767px){
    #wrapper{padding-top: 5em;}
    .fullscreen-btn-container{font-size: 3em;}
    .fullscreen-btn-container input[type="range"]{width: 70%;}
}

@media screen and (orientation:portrait) and (max-width: 767px){
    .warning-msg{
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ccc;
        text-align: center;
        padding: 5em;
        color: #f00;
        font-weight: 600;
    }
    
}