.follow{
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.06);
    border-radius: .75rem;
    overflow: hidden;
    padding: 0.625rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1rem;
    margin: 0 0 1rem;
    .image-holder{
        width: 3.125rem;
        height: 3.125rem;
        border-radius: .375rem;
        overflow: hidden;
        margin: 0 1.25rem 0 0;
        img {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;
            object-fit: cover;
        };
    }
    h3{
        font-size: 1rem;
        font-weight: 400;
        font-family: 'Roboto';
        margin: 0;
    }
    p{margin: 0;}
}