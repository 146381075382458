.comments-modals{
    .modal-content{
        border-radius: 1.125rem;
        border: 0;
    }
    .modal-header{
        padding-inline: 2rem;
        padding-top: 2rem;
        border: none;
    }
    .modal-title{
        font-family: "Rozha One", serif;
        font-size: 2rem;
    }
    .modal-body{
        padding: 2rem;
        padding-top: 0;
        .form-control{
            height: 3rem;
            background: #fff;
            box-shadow: 0px 1px 12px rgb(0 0 0 / 6%);
            border-radius: 6px;
            margin: 0;
            border: none;
            font-size: 0.875rem;
            line-height: 1.1875;
            padding: 1rem 4.303%;
            &#messageControl{
                height: auto;
                resize: none;
            }
        }
        .btn-primary{
            display: block;
            width: 100%;
            background: #679CF6;
            border-radius: 10px;
            border-color: #679CF6;
            font-size: 1rem;
            line-height: 1.2;
            padding: .75rem 3.768%;
            transition: all 0.3s ease;
        }
    }
}