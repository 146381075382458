.company{
    cursor: pointer;
    margin: 0.75em 0;
    border-radius: 8px;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
    .content{
        padding: .75rem;
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;
        line-height: 1.6;
        h1{
            font-size: 1rem;
            margin: 0;
            margin-top: .625rem;
        }
        img{
            width: 100%;
            border-radius: 6px;
            height: 6rem;
            object-fit: contain;
            max-width: 50%;
            margin: 0 auto 1rem;
        }
        .btn{
            font-size: 0.875rem;
            background: #679CF6;
            border-color: #679CF6;
            color: #fff;
        }
    }
}
.companySlider{
    // h1{
    //     font-size: 1.25em;
    // }
    .swiper{
        padding-inline: 5px;
    }
}