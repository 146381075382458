.interests-modal{
    .modal-header{
        display: block;
        padding: 1.125rem 1.375rem;
        font-size: 1rem;
        position: relative;
        @media only screen and (min-width: 768px){
            padding: 1.75rem 2.375rem;
            font-size: 1.25rem;
        }
        @media only screen and (min-width: 992px){
            padding: 2.125rem 3.375rem;
        }
        .modal-title{
            font-family: 'Rozha One';
            font-size: 1.75rem;
            margin: 0 0 .75rem;
            @media only screen and (min-width: 768px){
                font-size: 1.75rem;
                margin: 0 0 .875rem;
            }
            @media only screen and (min-width: 992px){
                font-size: 2.25rem;
                margin: 0 0 1.125rem;
            }
        }
        p{margin: 0;}
        .btn-close{
            position: absolute;
            right: 1.375rem;
            top: 1.125rem;
            @media only screen and (min-width: 992px){
                right: 3.375rem;
                top: 2.125rem;
            }
        }
    }
    .modal-body{
        padding: 1.125rem 1.375rem;
        font-size: 1rem;
        font-weight: 500;
        @media only screen and (min-width: 768px){
            padding: 1.75rem 2.375rem;
            font-size: 1.25rem;
        }
        @media only screen and (min-width: 992px){
            padding: 2.125rem 3.375rem;
        }
    }
}