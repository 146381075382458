.interests-section{
    font-size: 1rem;
    @media only screen and (min-width: 768px){
        font-size: 1.25rem;
    }
    .btn-reset{
        color: #679CF6;
        font-size: 1rem;
        padding: 0;
        @media only screen and (min-width: 768px){
            font-size: 1.25rem;
        }
    }
    .form-switch{
        padding: 0;
        margin: 0 0 1.375rem;
        @media only screen and (min-width: 768px){
            margin: 0 0 2.375rem;
        }
        .form-check-label{
            max-width: 80%;
        }
    }
    .form-check{
        .form-check-input{
            float: right;
        }
    }
    .btn-addmore{
        color: #679CF6;
        padding: 0;
    }
    .look-email{
        margin: 2.5rem 0 0;
        .form-check-label{
            font-size: 1.275rem;
            font-family: 'Rozha One';
            font-weight: 400;
            @media only screen and (min-width: 768px){
                font-size: 1.5rem;
            }
            @media only screen and (min-width: 992px){font-size: 1.875rem;}
        }
    }
}