.opinion-form {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        font-size: 0.625rem;
        text-transform: uppercase;

        @media only screen and (min-width: 768px) {
            font-size: 0.875rem;
        }

        li {
            flex: 0 0 9%;
            max-width: 25%;
            text-align: center;

            &:nth-child(2) {
                flex: 0 0 6%;
            }

            &:nth-child(3) {
                flex: 0 0 11%;
                text-align: right;
            }

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    .num-list {
        li {
            &:nth-child(2) {
                flex: 0 0 10%;
            }

            &:nth-child(3) {
                text-align: center;
                flex: 0 0 12%;
                padding: 0 0 0 3%;
            }
        }
    }

    .form-range {
        overflow: hidden;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;

        &::-webkit-slider-runnable-track {
            height: 16px;
            -webkit-appearance: none;
            appearance: none;
            color: #679CF6;
            // margin-top: -1px;
            background: #E4E4E4;
            overflow: hidden;
        }

        &::-webkit-slider-thumb {
            width: 16px;
            -webkit-appearance: none;
            appearance: none;
            height: 16px;
            margin-top: 0;
            cursor: ew-resize;
            background: #679CF6;
            box-shadow: -905px 0 0 900px #679CF6;
            border: 3px solid #fff;

        }

        &::-moz-range-progress {
            background-color: #679CF6;
            border: 3px solid #679CF6;
        }

        &::-moz-range-track {
            background-color: #e4e4e4;
            border: 3px solid #e4e4e4;
        }

        &::-ms-fill-lower {
            background-color: #679CF6;
        }

        &::-ms-fill-upper {
            background-color: #e4e4e4;
        }
    }

    .form-check {
        margin: 0;
        background: #E4E4E4;
        border-radius: .375rem;
        padding: .75rem 2.5rem;
        max-width: 48%;
        margin: 0 0 1.5rem;
        width: 100%;
        position: relative;
        overflow: hidden;
        font-size: 1rem;
        cursor: pointer;
        @media only screen and (min-width: 768px) {
            max-width: 10.625rem;
            margin: 0;
            padding: .75rem 3rem .75rem 2.25rem;
        }

        .form-check-label {
            display: block;
            color: #000;
            font-weight: 400;
            z-index: 1;
            position: relative;
            cursor: pointer;
        }

        .form-check-input {
            width: 1.25rem;
            height: 1.25rem;
            z-index: 1;
            position: relative;
            border: 2px solid #679CF6;
            margin-top: 0.15em;
            margin-left: -1.5em;
            cursor: pointer;
            &:focus {
                box-shadow: none;
            }
            &:checked {
                background-color: #fff;
                border: 2px solid #fff;

                @media only screen and (min-width: 768px) {
                    border: 5px solid #fff;
                }

                &[type="radio"] {
                    background: #679CF6;
                }

                ~.value {
                    &:before {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                +.form-check-label {
                    color: #fff;
                }
            }
        }

        .value {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            background: #fff;
            color: #679CF6;
            width: 3rem;
            font-size: 1rem;
            padding: 0.3125rem;
            &:before {
                position: absolute;
                right: 100%;
                left: -999px;
                top: 0;
                bottom: 0;
                background: #679CF6;
                content: '';
                opacity: 0;
                visibility: hidden;
                transition: all .3s ease;
            }
        }
    }
}