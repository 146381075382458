.customSliderContainer .swiper {
  padding-bottom: 3rem;
}

.customSliderContainer a {
  text-decoration: none;
}

.customSliderContainer .swiper-slide {
  @media only screen and (min-width: 768px){
    visibility: hidden;
  }
  &.swiper-slide-visible{
    @media only screen and (min-width: 768px){
      visibility: visible;
    }
  }
  &.swiper-slide-next{
    .sliderItem{
      @media only screen and (min-width: 768px){
        margin: 0 0 0 45%;
      }
    }
  }
  &.swiper-slide-prev{
    .sliderItem{
      @media only screen and (min-width: 768px){
       margin: 0 0 0 -45%;
      }
    }
  }
}
.swiper-pagination{
  top: auto !important;
  bottom: 0 !important;
  margin-top: 15px;
  .swiper-pagination-bullet{
    background: #999;
    width: 8px;
    height: 8px;
    &.swiper-pagination-bullet-active{
      width: 10px;
      height: 10px;
      background: #679CF6;
    }
  }
}

.customSliderContainer .swiper-slide-active{
  opacity: 1;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  
  .slider-content{
    position: relative;
    z-index: 1;
  }
  .sliderItem{
    width: auto;
    margin: 0;
    @media only screen and (min-width: 768px){
      margin: 0 -72.4%;
    }
    .slider-content{
      opacity: 1;
      visibility: visible;
    }
    &:before{
      background: rgba(0,0,0,0.4);
    }
  }
}

.customSliderContainer .sliderItem {
  height: 27.5rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: scroll;
  background-color: gray;
  border-radius: 2.6rem;
  position: relative;
  color: #fff;
  @media only screen and (min-width: 1920px){
    height: 40rem;
  }
  .slider-content{
    opacity: 0;
    visibility: hidden;
  }
  img{
    width: 100%;
  }
  h1{
    margin-top: 20px;
  }
  &:before{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0.4);
    content: '';
    border-radius: 2.6rem;
  }
}

// .customSliderContainer .swiper-slide-active {}