.milestones-holder{
    text-decoration: none;
    color: #31394D;
}
.milestone {
    margin: 0.75em 0px;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
    .image-holder{
        max-width: 40%;
        flex: 0 0 40%;
    }
    img{
        width: 100%;
        // height: 8.125rem;
        aspect-ratio: 2/1.4;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 8px;
    }
    .content {
        max-width: 60%;
        flex: 0 0 60%;
        padding: 0 1.125rem;
        font-size: 0.875rem;
        h1{
            font-size: 1rem;
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        img{
            width: 1em;
            height: auto;
        }
    }
}