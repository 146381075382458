.tab-sidebar{
    @media only screen and (min-width: 768px){border-right: 1px solid #ccc;}
    h2{
        @media only screen and (max-width: 1024px){
        font-size: 1.5rem;
    }
    }
    .nav-item{
        margin: 0 0 1.8175rem;
        @media only screen and (min-width: 768px){
            margin: 0 0 2.8175rem;
        }
    }
    .nav-link{
        font-size: 1rem;
        background: none;
        color: #31394D;
        padding: 0;
        cursor: pointer;
        &.active{
            background: none;
            color: #679CF6;
            border-bottom: 1px solid #679CF6;
            border-radius: 0;
            @media only screen and (min-width: 768px){
                border: none;
            }
        }
    }
}