.eventDetail{
    // width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 1.75rem 0 0;
    font-size: 1rem;
    line-height: 1.62;
    color: #31394D;
    @media only screen and (min-width: 768px){
        padding: 3.75rem 0 0;
        font-size: 1.25rem;
    }
    .img-holder{
        margin: 0 0 1.75rem;
        overflow: hidden;
        @media only screen and (min-width: 768px){
            max-width: 0 0 3.75rem;
        }
    }
    .articleImg{
        border-radius: 8px;
        width: 100%;
        aspect-ratio: 2/1;
        // max-width: 800px;
        // max-height: 500px;
        object-fit: cover;
        display: block;
        @media only screen and (min-width: 768px){
            border-radius: 20px;
        }
    }
    h1{
        font-size: 1.625rem;
        line-height: 1.26;
        @media only screen and (min-width: 768px){
            font-size: 2.625rem;
        }
    }
    .articleContent{
        margin: 1rem 0;
        @media only screen and (min-width: 768px){
            margin: 2rem 0;
        }
        .event-heading{
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .articleNavigation{
        p{
            color: #679CF6;
            font-size: 1rem;
            @media only screen and (min-width: 768px){font-size: 1.25rem;}
        }
        img{
            width: 16px;
            @media only screen and (min-width: 768px){width: 20px;}
        }
        .btn{
            color: #679CF6;
        }
        .bg-transparent{
            @media only screen and (max-width: 767px){padding-inline: .5rem;}
        }
        .event-reactions{
            span.bg-transparent{
                padding: 0;
            }
        }
    }
    .articleHeader{
        font-size: 1rem;
    }
    .article-heading{
        display: none;
    }
    .articleContent{
        .bg-white{background: none !important;}
    }
    .link-discuss{
        font-size: 1rem;
        cursor: pointer;
    }
}
.tags-area{
    h2{
        margin: 0 0 .625rem;
        font-size: 1.5rem;
        line-height: 1.6;
    }
    .rounded-pill{
        font-weight: 400;
        font-size: 1rem;
        padding: .3125rem 1.25rem;
        background: #679CF6;
        line-height: 1.6;
        margin: 0 .5rem 0 0;
    }
    // .pin-year{
    //     margin: 0 0 1.5rem;
    //     .form-label{
    //         font-size: 0.875rem;
    //         margin: 0 0 .625rem;
    //     }
    //     .form-control{
    //         width: 2.625rem;
    //         height: 2.625rem;
    //         font-weight: 500;
    //         font-size: 1.25rem;
    //         margin: 0 .625rem 0 0;
    //         background: #fff;
    //         box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    //         border: none;
    //         text-align: center;
    //     }
    // }
}
.prediction-field{
    position: relative;
    display: block;
    width: 100%;
    color: #000;
    border: none;
    text-align: left;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    .form-control{
        height: 3.125rem;
        overflow: hidden;
        padding: 1rem 3rem 1rem 1rem;
    }
    .btn{
        position: absolute;
        right: .75rem;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        line-height: 1;
        border-radius: 50% !important;
        width: 1.625rem;
        height: 1.625rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    &:hover{
        .btn{
            color: #fff;
            border-color: #fff;
        }
    }
}
swd-pin-field{
    margin: 0 0 1.5rem;
    .pin-year{
        width: 2.625rem;
        height: 2.625rem;
        font-weight: 500;
        font-size: 1.25rem;
        margin: 0 .625rem 0 0;
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border: none;
        text-align: center;
        overflow: hidden;
        border-radius: .375rem;
    }
}