@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Rozha+One&display=swap');

html {
    font-size: 14px;
    ::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (min-width: 768px) {
        font-size: 16px;
    }

    @media only screen and (min-width: 2500px) {
        font-size: 24px;
    }
}

body{
    background: linear-gradient(90deg, #FFFFFF -16.81%, #E4E4E4 100%);
}

#root {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.375;
    color: #31394D;
}

button:focus , button:active , .btn:active , .btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.site-frame{
    @media only screen and (max-width: 991px){
        padding-bottom: 7rem;
    }
}

a {
    color: #679CF6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Rozha One', serif;
}

.mh-100vh {
    min-height: 100vh;
}

.pointer{
    cursor: pointer;
}

.text-color-default {
    color: #31394D;
}

.login-area {
    padding: 2rem 0;

    .card {
        border-radius: 1.125rem;
        padding: 1.75rem 8.65%;

        @media only screen and (min-width: 1920px) {
            padding: 2.25rem 8.65%;
        }

        .card-header {
            font-size: 2.25rem;
            line-height: 1.42;
            font-family: 'Rozha One', serif;
            margin: 0 0 1.5rem;
            font-weight: 400;

            @media only screen and (min-width: 1400px) {
                font-size: 2.25rem;
            }

            @media only screen and (min-width: 1920px) {
                font-size: 2.25rem;
                margin: 0 0 2.3125rem;
            }
        }
    }

    .logo {
        text-align: center;
        margin: 0 auto 1.4125rem;
        max-width: 140px;

        @media only screen and (min-width: 768px) {
            margin: 0 0 2.8125rem;
            max-width: inherit;
        }

        @media only screen and (min-width: 1400px) {
            margin: 0 0 1.8125rem;
        }

        @media only screen and (min-width: 1600px) {
            margin: 0 0 2.8125rem;
        }

        @media only screen and (min-width: 1920px) {
            margin: 0 0 4.8125rem;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }

    form {
        label {
            font-size: 1rem;
            line-height: 1.1875;
            margin: 0 0 10px;
            display: block;

            @media only screen and (min-width: 1920px) {
                margin: 0 0 .875rem;
            }

            &.form-check-label {
                font-size: .875rem;
            }
        }

        .form-control {
            height: 50px;
            background: #fff;
            box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.06);
            border-radius: 6px;
            margin: 0;
            border: none;
            font-size: .875rem;
            line-height: 1.1875;
            padding: 1rem 10.303% 1rem 4.303%;

            @media only screen and (min-width: 1400px) {
                height: 3rem;
                padding: 1rem 10.303% 1rem 4.303%;
            }
        }

        .form-check {
            padding-left: 1em;
            margin: 0;

            .form-check-input[type=checkbox] {
                border-color: #000;
                border-radius: 0;
                width: 15px;
                height: 15px;
                margin-top: 0;
                margin-left: -1em;
                margin-right: .5em;

                @media only screen and (min-width: 768px) {
                    // margin-top: .1em;
                    margin-right: .25em;
                }

                @media only screen and (min-width: 1200px) {
                    // margin-top: 0.5em;
                }
            }

            .form-check-label {
                margin: 0;
                display: inline-block;
            }

            .form-check-input:checked {
                background-color: #679CF6;
            }
        }

        .btn-primary {
            display: block;
            width: 100%;
            background: #679CF6;
            border-radius: 10px;
            border-color: #679CF6;
            font-size: 1rem;
            line-height: 1.2;
            padding: 1rem 3.768%;
            transition: all .3s ease;

            @media only screen and (min-width: 1400px) {
                padding: 1rem 3.768%;
            }

            &:hover {
                background: none;
                border-color: #679CF6;
                color: #679CF6;
            }
        }

        .register-note {
            font-size: 1rem;
            line-height: 1.2;

            .btn-link {
                font-weight: 600;
                color: #679CF6;
                transition: all .3s ease;

                &:hover {
                    color: #31394D;
                }
            }
        }
    }

    .col-xxl-3 {
        @media only screen and (min-width: 1400px) {
            width: 26.8%;
        }
    }

    .forgot-pw {
        font-size: .875rem;
    }
}

.error-msg {
    background: #F74A4A;
    color: #fff;
    padding: 2.275vh 0.785%;

    .error-icon {
        margin: 0 .8% 0 0;
    }

    .error-content {
        font-family: 'Roboto';
        font-size: 0.73vw;

        strong {
            font-family: 'Rozha One';
            font-size: 0.835vw;
            font-weight: 400;
            display: block;
            margin: 0 0 3px;
        }
    }
}

.tags-header {
    text-align: center;
    // max-width: 77.6%;
    margin: 0 auto 4.5rem;
    font-size: 1.25rem;
    line-height: 1.6;

    @media only screen and (min-width: 768px) {
        max-width: 77.6%;
    }

    h2 {
        font-weight: 400;
        font-size: 1.875rem;
        margin: 0 0 1rem;
    }
}

.tags-form {
    h3 {
        font-weight: 500;
        font-size: 1.25rem;
        font-family: 'Roboto', sans-serif;
        text-align: center;
        margin: 0 0 1.9375rem;

        @media only screen and (min-width: 768px) {
            margin: 0 0 3.9375rem;
        }
    }

    .form-check {
        // flex: 0 0 47%;
        // max-width: 47%;
        padding-left: 0 !important;
        margin: 0 1.5% 1rem !important;

        @media only screen and (min-width: 768px) {
            padding-left: 1em !important;
            // flex: 0 0 30%;
            // max-width: 30%;
            margin: 0 0 1rem !important;
        }

        @media only screen and (min-width: 1200px) {
            // flex: 0 0 20%;
            // max-width: 20%;
        }

        .form-check-input {
            opacity: 0;

            &:checked {
                ~.form-check-label {
                    border-color: #679CF6;

                    &:before {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        .form-check-label {
            position: relative;
            box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
            font-size: 1rem;
            padding: 1.125rem;
            display: block;
            width: 100%;
            text-align: center;
            border: 2px solid transparent;
            border-radius: 6px;

            // overflow: hidden;
            &:before {
                position: absolute;
                content: '';
                right: -10px;
                top: -10px;
                z-index: 1;
                background: url(../assets/images/tick.svg) no-repeat 50% 50% / 26px;
                width: 26px;
                height: 26px;
                opacity: 0;
                visibility: none;
            }
        }
    }

    .tags-wrapper {
        margin: 0 0 4.125rem;
    }

    .btn-primary {
        max-width: 406px;
        margin: 0 auto;
    }
}
.main-navigation{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    @media only screen and (min-width: 992px){
        position: static;
        background: none;
        box-shadow: none;
    }
    .main-menu {
        li {
            font-size: 0;
            flex: 0 0 25%;
            max-width: 25%;
            @media only screen and (min-width: 992px){
                flex: none;
            }
            // &:first-child{
            //     a{
            //         border-left: 1px solid #ddd;
            //         @media only screen and (min-width: 992px){border: none;}
            //     }
            // }
            a {
                font-size: .75rem;
                text-decoration: none;
                transition: all .3s ease;
                font-weight: 400;
                display: block;
                padding: .75rem .5rem;
                letter-spacing: 1px;
                // border-right: 1px solid #ddd;
                // border-radius: 10px 10px 0 0;
                // box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
                // background: #fff;
                @media only screen and (min-width: 992px){
                    font-size: 1rem;
                    font-weight: 500;
                    padding: 0;
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    background: none;
                    letter-spacing: 0;
                }
                &:hover {
                    text-shadow: 0 0 .01px #679CF6;
                }
            }
    
            .icon-holder {
                margin: 0 0 8px;
                font-size: 1.25rem;
                @media only screen and (min-width: 992px){margin: 0 .725rem 0 0;}
            }
        }
    }
}

.header-links {
    li {
        margin: 0 0 0 13.5%;
        &:last-child{
            @media only screen and (max-width: 767px){
                margin: 0;
            }
        }

        &:first-child {
            margin: 0;
        }
    }

    .btn {
        background: none;
        color: #679CF6;
        border: none;
        padding: 0;
        font-size: 1.725rem;

        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
        }
    }
    .profile-widget{
        .dropdown-menu{
            .dropdown-item{padding-block: 0.5rem;}
        }
    }

    .user-icons {
        width: 44px;
        height: 44px;
        display: block;
        background-color: #C4C4C4;
        border-radius: 50%;
        overflow: hidden;
        &:active,
        &:focus{
            background-color: transparent;
            border-color: transparent;
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0;
            object-fit: cover;
        }
    }
    .search-icon{
        &:active,
        &:focus{
            background-color: transparent;
            border: transparent;
            box-shadow: none !important;
        }
    }
    .menu-opener{
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 44px;
        background: #fff;
        text-align: center;
        width: 40px;
        height: 40px;
        padding: 0;
        border-radius: 50%;
        z-index: 100;
        border: 1px solid #ddd;
        box-shadow: 0 0 5px rgb(0 0 0 / 30%);
        @media only screen and (min-width: 992px){
            position: static;
            transform: translateX(0);
            background: none;
            width: auto;
            height: auto;
            border-radius: 0;
            border: none;
            box-shadow: none;
        }
    }
}
.show>.btn-primary.dropdown-toggle.search-icon{background-color: transparent;}

.dropdown-toggle::after {
    display: none
}
.search-widget{
    .dropdown-menu{
        padding: 1rem;
        inset: 10px -7rem auto auto !important;
        min-width: 14rem;
        @media only screen and (max-width: 767px){
            right: -100% !important;
        }
        form{position: relative;}
        .btn{
            position: absolute;
            right: 8px;
            top: 0;
            bottom: 0;
            line-height: 1;
        }
        .form-control{
            padding-right: 1.7rem;
            font-size: 0.9rem;
        }
    }
}

.sec-heading{
    font-size: 1.875rem;
    margin: 0 0 1.875rem;
}
.btn-back{
    background: none;
    color: #679CF6;
    border: none;
    padding: 0;
    margin: 0 0 1rem;
    &:focus-visible,
    &:focus,
    &:active,
    &:hover{
        background: none;
        color: #31394D;
        outline: none;
        box-shadow: none;
    }
}