.setting-options{
    @media only screen and (min-width: 768px){border-left: 1px solid #ccc;}
    .setting-col{
        margin: 0 0 2.375rem;
        .form-check{
            margin: 0 0 1rem;
            &.form-switch{padding-left: 0;}
            .form-check-label{
                font-size: 1.25rem;
                font-family: 'Roboto';
                font-weight: 500;
                max-width: 90%;
            }
            .form-check-input{
                float: right;
            }
        }
    }
    h2{
        a{
            text-decoration: none;
            font-size: 1.875rem;
        }
    }
}