.search-page {
    h1 {
        font-size: 1.875rem;
        line-height: 1.43;
        color: #31394D;
        font-weight: 400;
        margin: 0 0 1.625rem;
    }

    .search-area {
        overflow: hidden;
        position: relative;

        .form-control {
            background: #FFFFFF;
            box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.06);
            border-radius: .625rem !important;
            border: none;
            background: #fff;
            height: 3.375rem;
            padding: 1.125rem 1.125rem 1.125rem 4rem;
            width: 100%;
            color: #000000;
        }

        .btn-link {
            position: absolute;
            left: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
            color: #DEDEDE;
            font-size: 1.25rem;
            padding: 0;
        }

        .btn-remove {
            left: auto;
            right: 1.5rem;
            outline: none;
            box-shadow: none;
        }
    }
}