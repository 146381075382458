.follower-modal{
    position: relative;
    &:before{
        position: absolute;
        content: '';
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        width: 0.375rem;
        height: 0.375rem;
        background: #D5D5D5;
        border-radius: 50%;
        margin-right: .36rem;
        @media only screen and (min-width: 768px){
            margin-right: 2.8rem;
        }
    }
    &:first-child{
        &:before{display: none;}
    }
    .btn-link{
        text-decoration: none;
        color: #31394D;
    }
}
.followers-modal-list{
    padding: 0.25rem;
    margin: 0;
    list-style: none;
    .content-area{
        flex: 0 0 70%;
        max-width: 70%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: #31394D;
        .image-holder{
            width: 2.375rem;
            height: 2.375rem;
            border-radius: 50%;
            margin: 0 .875rem 0 0;
            overflow: hidden;
            img{
                display: block;
                width: 100%;
                height: 100%;
                margin: 0;
                object-fit: cover;
            }
        }
        .info-area{
            overflow: hidden;
            font-family: 'Roboto';
            font-size: 0.875rem;
            strong{
                font-family: 'Rozha One';
                font-weight: 400;
                font-size: 1rem;
            }
            p{margin: 0;}
        }
    }
    .follow-btn{
        background: #679CF6;
        color: #fff;
        font-size: 0.875rem;
        border-color: #679CF6;
        max-width: 30%;
    }

}