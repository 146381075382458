.timeline-slide-widget{
    cursor: pointer;
    width: 4rem;
    background: #fff;
    margin: 0 auto;
    padding: 0.625rem;
    border-radius: 2.75rem;
    color: #679CF6;
    font-size: 1rem;
    font-family: 'Rozha One', serif;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    .image-holder{
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 0 .5rem;
        img{
            display: block;
            height: 100%;
            margin: 0;
            width: 100%;
            object-fit: cover;
        }
    }
    strong{
        font-weight: 400;
        font-size: 1rem;
    }
    &.h-1{height: 6rem;}
    &.h-2{height: 10rem;}
    &.h-3{height: 14rem;}
    &.h-4{height: 18rem;}
    &.h-5{height: 22rem;}
}