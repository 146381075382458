.wrapper {
    width: 100%;
    // max-width: 1920px;
    margin: 0 auto;
}

.menuWrapper{
    background-color: #ffffff;
    position: fixed;
    width: 100vw ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    border-radius: 0px 0px 1.25em 1.25em;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
    -webkit-box-shadw: 0px 5px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
    @media only screen and (min-width: 768px){
        bottom: auto;
    }
    .menuContentWrapper{
        padding: 1.5em 0em;
        position: relative;
    }
    .menuCloseBtn{
        position: absolute;
        top: 0.75em;
        right: 2em;
    }
}