.company-details-holder{
    max-width: 900px;
    margin: 0 auto;
    overflow: hidden;
    .img-holder{
        overflow: hidden;
        background: #F6F9FF;
        border-radius: 1.275rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        min-height: 18.125rem;
        margin: 0 0 2.5rem;
        @media only screen and (min-width: 768px){
            min-height: 28.125rem;
        }
        img{
            max-width: 50%;
            margin: 0 auto;
        }
    }
    header{
        font-size: 1.25rem;
        margin: 0 0 1.875rem;
        h1{
            font-size: 1.875rem;
            margin: 0 0 .875rem;
        }
        p{margin: 0;}
    }
    .details-area{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: .875rem 0;
            border-top: 1px solid #31394D;
            // &:first-child{border-top: 1px solid #31394D;}
            span{
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    .articleNavigation{
        .btn{color: #679CF6;}
    }
}