.tags-form {
    .form-area {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1.5rem;
        @media only screen and (min-width: 992px) {
            flex: 0 0 66%;
            max-width: 66%;
            margin-bottom: 0;
        }

        .form-row {
            margin-block: 1rem;
            padding-left: 1.75rem;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                bottom: 0;
                background: #ee1e26;
                border-radius: .17rem;
                width: 1.29rem;
            }

            &:nth-child(2):before {
                background: #f26623;
            }

            &:nth-child(3):before {
                background: #ffde17;
            }

            &:nth-child(4):before {
                background: #00a14b;
            }

            &:nth-child(5):before {
                background: #2e58ac;
            }
        }
    }
}

.tags-form {
    .change-image-area {
        flex: 0 0 100%;
        max-width: 100%;
        background: #000;
        position: relative;
        @media only screen and (min-width: 992px){
            flex: 0 0 30%;
            max-width: 30%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;
            object-fit: contain;
        }
    }
    .settings-col {
        max-width: 50%;
        flex: 0 0 50%;
    }   
    .btn-tag-submit {
        /* display: block; */
        background: #0d6efd;
        border: none;
        color: #fff;
        box-shadow: none;
        font-size: 1rem;
        padding: 0.75rem 1.5rem;
        border-radius: .25rem;
        text-transform: uppercase;
    }
    .change-image-area button {
        position: absolute;
        right: .75rem;
        top: .75rem;
        background: #0d6efd;
        color: #fff;
        font-weight: 600;
        z-index: 1;
        border-radius: .2rem;
        font-size: 0.75rem;
        padding: .25rem 1rem;
        overflow: hidden;
        border: none;
    }
}

.tags-modal {
    .modal-content {
        border: none;
    }
}
#__range-picker-container .calendar {
    z-index: 9999;
}

.selectbale_wrapper {
    background: hsla(0, 0%, 100%, .7);
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    height: 100px;
    margin: 5px;
    position: relative;
    vertical-align: middle;
    width: 100px;
    label {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}