.articleDetail{
    // width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 1.75rem 0 0;
    font-size: 1rem;
    line-height: 1.62;
    color: #31394D;
    @media only screen and (min-width: 768px){
        padding: 3.75rem 0 0;
        font-size: 1.25rem;
    }
    .img-holder{
        margin: 0 0 1.75rem;
        overflow: hidden;
        @media only screen and (min-width: 768px){
            max-width: 0 0 3.75rem;
        }
    }
    .articleImg{
        border-radius: 8px;
        width: 100%;
        aspect-ratio: 2/1;
        // max-width: 800px;
        // max-height: 500px;
        object-fit: cover;
        display: block;
        @media only screen and (min-width: 768px){
            border-radius: 20px;
        }
    }
    h1{
        font-size: 1.625rem;
        line-height: 1.26;
        @media only screen and (min-width: 768px){
            font-size: 2.625rem;
        }
    }
    .articleContent{
        margin: 1rem 0;
        @media only screen and (min-width: 768px){
            margin: 2rem 0;
        }
    }
    .articleNavigation{
        p{
            color: #679CF6;
            font-size: 1rem;
            @media only screen and (min-width: 768px){font-size: 1.25rem;}
        }
        img{
            width: 16px;
            @media only screen and (min-width: 768px){width: 20px;}
        }
        .btn{
            color: #679CF6;
        }
    }
    .articleHeader{
        font-size: 1rem;
    }
    .article-heading{
        display: none;
    }
    .articleContent{
        .bg-white{background: none !important;}
        .wp-block-supertrends-figure{
            video,
            img,
            iframe{
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9;
                width: 100%;
                height: 100%;
            }
        }
    }
    .link-discuss{
        font-size: 1rem;
        cursor: pointer;
    }
}
.wp-block-supertrends-figure iframe{
    display: block;
    width: 100%;
}