.companies-grid{
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 1.25rem;
    row-gap: 1.75rem;
    width: 100%;
    @media only screen and (min-width: 768px){
        grid-template-columns: repeat(3, 1fr);
        row-gap: 3.75rem;
    }
    @media only screen and (min-width: 992px){
        grid-template-columns: repeat(4, 1fr);
    }
    @media only screen and (min-width: 1200px){
        grid-template-columns: repeat(5, 1fr);
    }
    @media only screen and (min-width: 1920px){
        grid-template-columns: repeat(6, 1fr);
    }
    @media only screen and (min-width: 3600px){
        grid-template-columns: repeat(7, 1fr);
    }
}
.companies-loader{
    .loader-area{margin-top: -14.5vh;}
}