.column{
    &.expert{
        padding: 0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        .image-holder{
            align-items: flex-end;
            margin: 0 0 3.125rem;
            @media only screen and (max-width: 767px){
                height: 3.75rem;
                margin: 0 0 2.25rem;
            }
        }
        .image-frame{
            width: 3.625rem;
            height: 3.625rem;
            background: #fff;
            padding: .325rem;
            border-radius: 50%;
            margin: 0 0 -2.3125rem;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            position: relative;
            // overflow: hidden;
            @media only screen and (min-width: 768px){
                width: 4.625rem;
                height: 4.625rem;
                padding: .5rem;
            }
            img{
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: fill;
                border-radius: 50%;
                max-height: inherit;
            }
            .expert-icon{
                position: absolute;
                right: -.5rem;
                bottom: -1rem;
                z-index: 1;
            }
        }
        .content-holder{
            padding: 0.75rem;
            text-align: center;
        }
        .btn{border-color: #679CF6;}
    }
}