.company-details-holder {
    &.expert-details-holder {
        max-width: 900px;
        margin: 0 auto;
        .img-holder {
            display: block;
            min-height: inherit;
            img {
                max-width: 100%;
                display: block;
                width: 100%;
                height: 28rem;
                object-fit: cover;
            }

        }
    }
}