.timelineHolder{
    .timelineHeader{
        margin: 0 0 2.8125rem;
        @media only screen and (min-width: 768px){
            margin: 0 0 5.8125rem;
        }
    }
    .btn-holder{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        .btn{
            background: #fff;
            border-radius: 50%;
            width: 3.0625rem;
            height: 3.0625rem;
            display: block;
            box-sizing: border-box;
            margin: 0 0 0 .75rem;
            padding: 0;
            transition: all .25s ease;
            img{
                // filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(321deg) brightness(110%) contrast(110%);
                display: block;
                margin: 0 auto;
                border-radius: 50%;
                width: 1.9375rem;
                height: 1.9375rem;
                &.icon-hover{display: none;}
            }
            &:hover{
                background: #679CF6;
                img{
                    &.icon{display: none;}
                    &.icon-hover{display: block;}
                }
            }
        }
    }
}
.timelineEvents{margin: -1.825rem 0 0;}



  .loading{
    // position:absolute;
    // top:50%;
    // left:50%;
    // transform:translate(-50%,-50%);
    // height:40px;
    display:flex;
    align-items:center;
    justify-content: center;
}
.obj{
    width:15px;
    height:50vh;
    background:#fff;
    margin:0 9px;
    border-radius:15px;
    animation:loading .5s infinite;
}
    .obj:nth-child(2) {
        animation-delay: .1s;
    }
    .obj:nth-child(3) {
        animation-delay: .2s;
    }
    .obj:nth-child(4) {
        animation-delay: .3s;
    }
    .obj:nth-child(5) {
        animation-delay: .4s;
    }
    .obj:nth-child(6) {
        animation-delay: .5s;
    }
    .obj:nth-child(7) {
        animation-delay: .6s;
    }
    .obj:nth-child(8) {
        animation-delay: .7s;
    }
    .obj:nth-child(9) {
        animation-delay: .8s;
        @media only screen and (max-width: 767px){display: none;}
    }
    .obj:nth-child(10) {
        animation-delay: .9s;
        @media only screen and (max-width: 767px){display: none;}
    }
    .obj:nth-child(11) {
        animation-delay: .10s;
        @media only screen and (max-width: 767px){display: none;}
    }
    .obj:nth-child(12) {
        animation-delay: .11s;
        @media only screen and (max-width: 767px){display: none;}
    }
    .obj:nth-child(13) {
        animation-delay: .12s;
        @media only screen and (max-width: 767px){display: none;}
    }
    .obj:nth-child(14) {
        animation-delay: .13s;
        @media only screen and (max-width: 767px){display: none;}
    }
    .obj:nth-child(15) {
        animation-delay: .14s;
        @media only screen and (max-width: 767px){display: none;}
    }
@keyframes loading{
    0%{
        height:0;
    }
    50%{
        height:50vh;
    }
    100%{
        height:0;
    }
}

