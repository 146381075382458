.account-details-block{
    .account-header{
        overflow: hidden;
        text-align: center;
        font-size: 1.25rem;
        margin: 0 0 2.5rem;
        @media only screen and (min-width: 992px){
            margin: 0 0 4rem;
        }
    }
    .image-holder{
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        border: 1rem solid #fff;
        box-shadow: 0px 16px 45px -15px rgba(2, 5, 132, 0.2);
        margin: 0 auto 1rem;
        overflow: hidden;
        @media only screen and (min-width: 992px){
            border: 1.25rem solid #fff;
            margin: 0 auto 1.25rem;
        }
        
        img{
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;
            object-fit: cover;
        }
    }
    .form-label{
        font-weight: 500;
        font-size: .875rem;
        display: block;
        margin: 0 0 .5rem;
        @media only screen and (min-width: 768px){
            font-size: 1rem;
            margin: 0 0 .625rem;
        }
    }
    .form-control{
        background: #fff;
        height: 3rem;
        border: none;
        box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.06);
        border-radius: .375rem;
        margin: 0 0 .825rem;
        padding: .875rem;
        @media only screen and (min-width: 768px){
            height: 3.75rem;
            padding: 1.25rem;
            margin: 0 0 1.5rem;
            border-radius: .625rem;
        }
        @media only screen and (min-width: 992px){
            margin: 0 0 2.9375rem;
        }
    }
    textarea{
        min-height: 150px;
        resize: none;
    }
    .btn-primary{
        font-size: 1rem;
        background: #679CF6;
        color: #fff;
        border: none;
        display: block;
        max-width: 20.375rem;
        margin: 0 auto;
        width: 100%;
        padding: .875rem;
    }
}