.column{
    background: #fff;
    border-radius: .75rem;
    padding: .75rem .75rem 1.25rem;
    color: #31394D;
    font-size: 0.875rem;
    line-height: 1.6;
    max-width: 100%;
    position: relative;
    .image-holder{
        background: #F6F9FF;
        border-radius: .5rem;
        height: 5.75rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0 0 .625rem;
        @media only screen and (min-width: 768px){
            height: 7.75rem;
        }
        img{
            max-width: 50%;
            display: block;
            margin: 0 auto;
            height: auto;
            height: 3.5rem;
            object-fit: contain;
        }
    }
    .content-holder{
        overflow: hidden;
        header{
            font-size: 0.875rem;
            color: #31394D;
            line-height: 1.6;
            margin: 0 0 .75rem;
            h2{
                font-size: 1.25rem;
                font-weight: 400;
                text-transform: uppercase;
                margin: 0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: 3rem;
                overflow: hidden;
            }
            p{margin: 0;}
        }
        p{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 0 0 .5rem;
        }
        .btn{
            display: block;
            width: 100%;
            font-size: 0.875rem;
            background: #679CF6;
            margin-top: 1rem;
        }
        .company-content-holder{
            height: 3rem;
            overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
        }
    }
}