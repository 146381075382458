.comment-form{
    overflow: hidden;
    padding: 1.5rem;
    border-radius: .5rem;
    .image-holder{
        width: 2rem;
        height: 2rem;
        overflow: hidden;
        border-radius: 50%;
        max-width: 2rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        img{
            display: block;
            width: 100%;
            height: auto;
            margin: 0;
            object-fit: cover;
        }
    }
    .comment-field{
        flex: 0 0 90%;
        max-width: 90%;
        @media only screen and (min-width: 768px){
            flex: 0 0 80%;
            max-width: 80%;
        }
        textarea{
            resize: none;
            width: 100%;
        }
    }
    .btn-holder{
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        @media only screen and (min-width: 768px){
            flex: 5.75rem;
            max-width: 5.75rem;
            text-align: left;
        }
    }
    .btn{
        width: 5.75rem;
        background: #679CF6;
        border-radius: .5rem;
        border-color: #679CF6;
    }
}