.timeline-chart{
    .chart-bar{
        font-size: 0.875rem;
        color: #4F4F4F;
        height: 40vh;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-end;
        time{
            display: block;
            margin: .5rem 0 0;
            text-align: center;
        }
    }
    .chart-bar-area{
        width: 100%;
        background: #BDBDBD;
        overflow: hidden;
        border-radius: .5rem;
        display: flex;
        align-self: flex-end;
    }
    .swiper-slide-active{
        .chart-bar-area{background: #679CF6;}
    }
    .swiper-button-next,
    .swiper-button-prev{
        background: #679CF6;
        color: #fff;
        width: 2rem;
        height: 2rem;
        box-sizing: border-box;
        border-radius: .325rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        &:after{
            font-size: 1.25rem;
        }
    }
    .swiper-button-next{right: 0;}
    .swiper-button-prev{left: 0;}
}