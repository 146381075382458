@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Rozha+One&display=swap');
.menuWrapper{
    .menu-list{
        max-height: 80vh;
        overflow-y: auto;
        z-index: 9999;
        position: relative;
    }
}
.menuContent{
    h1{
        color: #679CF6;
    }
}

.menuItem{
    .menuLink{
        color: #000000;
        font-family: 'Rozha One', serif;
        font-size: 1.375em;
        text-decoration: none;
        &:hover{
            color: #679CF6;
        }
    }
    p{
        color: #979BA5;
        font-size: 1em;
    }
}