.comment-area{
    overflow: hidden;
    background: #fff;
    padding: 1.125rem;
    border-radius: .5rem;
    font-size: 1rem;
    color: #67727E;
    .comment-header{
        margin: 0 0 1rem;
        .image-holder{
            width: 2rem;
            height: 2rem;
            overflow: hidden;
            border-radius: 50%;
            max-width: 2rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 0 1rem 0 0;
            img{
                display: block;
                width: 100%;
                height: auto;
                margin: 0;
                object-fit: cover;
            }
        }
        strong{
            font-size: 1rem;
            margin: 0 1.5rem 0 0;
        }
        time{
            font-size: 1rem;
            color: #67727E;
        }
        .btn-link{
            padding: 0;
            color: #C4C4C4;
            &:active,
            &:focus{
                outline: none;
                box-shadow: none;
            }
        }
    }
    .comments-options{
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 9;
        background: #fff;
        box-shadow: 0 0 10px rgba(0,0,0,.3);
        width: 8rem;
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            .btn-link{
                text-decoration: none;
                display: block;
                padding: .75rem 1rem;
                width: 100%;
                border-radius: 0;
                &:hover{
                    background: #C4C4C4;
                    color: #fff;
                }
            }
        }
    }
}
.comment-footer{
    .btn-link{
        font-size: 0.875rem;
        text-decoration: none;
        color: #748AA1;
        svg{
            color: #679CF6;
            margin-right: 0.375rem;
        }
        &.reply-btn{color: #679CF6;}
    }
}