.my-account{
    padding-bottom: 1.875rem;
    padding-top: 1.875rem;
    @media only screen and (min-width: 768px){
        padding-top: 3.875rem;
        padding-bottom: 3.875rem;
    }
}
.milestones-tab{
    max-height: calc(100vh - 12rem);
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &:-webkit-scrollbar {
        display: none;
    }
    .milestone-single{
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: .5rem;
        margin: 0 0 1rem;
        width: 100%;
        transition: all .3s ease-in-out;
        z-index: 9;
        .btn{
            color: #679CF6;
            &.btn-bookmark{
                display: flex;
                align-self: flex-end;
            }
        }
        cursor: pointer;
    }
    .image-holder{
        width: 4.625rem;
        height: auto;
        overflow: hidden;
        border-radius: .5rem;
        max-width: 25%;
        margin: 0 .5rem 0 0;
        @media only screen and (min-width: 768px){
            width: 5.625rem;
            height: 5.625rem;
            max-width: 20%;
        }
        img{
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;
            object-fit: cover;
        }
    }
    .milestone-content{
        flex: 0 0 72%;
        max-width: 72%;
        padding: .5rem;
        font-size: 0.75rem;
        @media only screen and (min-width: 375px){
            flex: 0 0 76%;
            max-width: 76%;
        }
        @media only screen and (min-width: 1200px){
            flex: 0 0 82%;
            max-width: 82%;
            // padding: 0;
        }
        h2{
            font-size: 1rem;
            line-height: 1.2;
            margin: 0 0 .5rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    &.articles-tab{
        .image-holder{
            width: 11rem;
            height: 100%;
            @media only screen and (min-width: 992px){
                height: 8rem;
                width: 11rem;
            }
            @media only screen and (min-width: 1200px){
                height: 10rem;
            }
        }
        .milestone-content{
            flex: 0 0 72%;
            max-width: 72%;
            padding: 0.5rem;
            @media only screen and (min-width: 768px){
                padding: .75rem;
                flex: 0 0 78%;
                max-width: 78%;
            }
            h2{margin: 0 0 .625rem;}
            time{
                display: block;
                margin: 0 0 .875rem;
            }
            p{
                display: none;
                @media only screen and (min-width: 768px){
                    // display: block;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
                &.authur-name{display: block;}
            }
        }
        .content{
            .authur-name{
                font-size: 0.75rem;
            }
            img{
                display: block;
                width: 1.375rem;
                height: 1.375rem;
                margin: 0;
                object-fit: cover;
                border-radius: 50%;
            }
            .review-area{
                img{
                    width: .75rem;
                    height: 0.75rem;
                }
            }
        }
    }
    &.companies-tab{
        .image-holder{
            background: #F6F9FF;
            height: 4.625rem;
            img{
                object-fit: contain;
                max-width: 80%;
                margin: 0 auto;
            }
        }
    }
    .link-holder{
        color: #31394D;
        text-decoration: none;
        position: relative;
        flex: 0 0 88%;
        max-width: 88%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    .milestones-holder{
        position: relative;
        flex: 0 0 88.2%;
        max-width: 88.2%;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        align-items: center;
    }
    .trigger-btn{
        &:focus{box-shadow: none;}
    }
    .btn-delete{
        position: absolute;
        right: 1px;
        top: 1px;
        bottom: 1px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        border-radius: .5rem;
        z-index: -1;
        border-color: transparent;
    }
    .dargbookmark{
        .milestone-single{
            transform: translateX(-2.5rem);
            border-radius: .5rem 0 0 .5rem;
        }
        .btn-delete{
            border-radius: 0 .5rem .5rem 0;
            z-index: 1;
            transition: all .6s ease-in-out;
            border-color: #dc3545;
        }
    }
    &.notifications{
        .milestone-content{
            flex: 0 0 68%;
            max-width: 68%;
            @media only screen and (min-width: 768px){
                flex: 0 0 73%;
                max-width: 73%;
            }
            @media only screen and (min-width: 1024px){
                flex: 0 0 77%;
                max-width: 77%;
            }
            @media only screen and (min-width: 1200px){
                flex: 0 0 81%;
                max-width: 81%;
            }
            @media only screen and (min-width: 1440px){
                flex: 0 0 84%;
                max-width: 84%;
            }
        }
    }
}