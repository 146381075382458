.user-details-holder {
    max-width: 908px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 80px);
    padding: 1.625rem 0 0;
    .user-details-frame {
        background: #F6F9FF;
        border-radius: 1.25rem;
        text-align: center;
        padding: 2.8125rem;
        box-sizing: border-box;
        font-size: 1.125rem;
        width: 100%;
        .image-holder {
            overflow: hidden;
            width: 11.5rem;
            height: 11.5rem;
            margin: -7.125rem auto 1.625rem;
            border-radius: 50%;
            border: 1.375rem solid #fff;
            background: #fff;
            img {
                display: block;
                width: 100%;
                height: 100%;
                margin: 0;
                object-fit: cover;
            }
        }

        .details-area {
            overflow: hidden;
            // max-width: 78%;
            margin: 0 auto;

            @media only screen and (min-width: 768px) {
                max-width: 78%;
            }

            h2 {
                font-size: 1.875rem;
                line-height: 1.43;
                margin: 0 0 1.125rem;
            }

            p {
                margin: 0 0 2.25rem;
            }
        }
    }

    .follow-btn {
        background: #679CF6;
        border-radius: .75rem;
        border: none;
        font-family: 'Rozha One';
        font-size: 1.25rem;
        padding: 0.75rem;
        min-width: 10.5rem;
        outline: none;
    }

    .unfollow-btn {
        background: #6C757D;
        border-radius: .75rem;
        border: none;
        font-family: 'Rozha One';
        font-size: 1.25rem;
        padding: 0.75rem;
        min-width: 10.5rem;
        outline: none;
    }
}