.account-details-block{
    &.edit-profile{
        .account-header{
            strong{
                display: block;
                margin: 0 0 1rem;
                @media only screen and (min-width: 992px){margin: 0 0 1.5rem;}
            }
            .btn{
                max-width: 170px;
                font-size: 0.875rem;
            }
        }
        .btn-delete-acc{
            text-decoration: none;
            text-align: center;
            // margin: 0 auto;
            display: block;
            color: #FF5A5F;
            font-size: 1.25rem;
            font-family: 'Rozha One';
            font-weight: 400;
            margin: 1.875rem auto 0;
        }
    }
}