.my-account{
    padding-bottom: 1.875rem;
    padding-top: 1.875rem;
    @media only screen and (min-width: 768px){
        padding-top: 3.875rem;
        padding-bottom: 3.875rem;
    }
}
.following-section{
    max-height: calc(100vh - 11.625rem);
    overflow-y: auto;
}