.lightspeed-widget{
    overflow: hidden;
    // background: #fff;
    cursor: pointer;
    color: #679CF6;
    .image-holder{
        overflow: hidden;
        background: #fff;
        border-radius: 50% 50% 0 0;
        padding: 0.3125rem;
        img{
            display: block;
            width: 100%;
            aspect-ratio: 1/1;
            overflow: hidden;
            border-radius: 50%;
            margin: 0;
            object-fit: cover;
        }
    }
    .content-holder{
        padding: 1rem 1.25rem 1.25rem;
        overflow: hidden;
        border-radius: 0 0 .625rem .625rem;
        background: #fff;
        margin-top: -1px;
        p{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 0;
            height: 60px;
            align-items: center;
            // display: flex;
            @media only screen and (min-width: 768px){
                height: 70px;
            }
        }
    }
    h2{
        color: #31394D;
        margin: 0 0 1.875rem;
    }
    span{
        color: #31394D;
        display: block;
        margin: 0 0 0.875rem;
    }
}
.lightspeed-slider{
    .swiper-slide-active{
        .lightspeed-widget{
            color: #fff;
            .image-holder{
                background: #679CF6;
            }
            .content-holder{background: #679CF6;}
            span,
            h2{color: #fff;}
        }
    }
}