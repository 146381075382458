.profile-sidebar{
    color: #31394D;
    font-size: 1rem;
    border-bottom: 1px solid #ccc;
    margin: 0 0 1.25rem;
    @media only screen and (min-width: 768px){
        border-right: 1px solid #ccc;
        border-bottom: none;
        margin: 0;
    }
    h2{
        font-size: 1.875rem;
        line-height: 1.434;
        margin: 0 0 1.25rem;
        @media only screen and (min-width: 768px){
            margin: 0 0 3rem;
        }
    }
    .nav-link{
        padding: 0;
        font-size: 1rem;
        color: #31394D;
        margin: 0 .5rem .5rem;
        white-space: nowrap;
        @media only screen and (min-width: 768px){
            font-size: 1rem;
            margin: 0 0 2.1875rem;
            white-space: auto;
        }
        &:hover,
        &.active{color: #679CF6;}
    }
    .nav{
        // width: 100%;
        overflow-x: auto;
        flex-wrap: nowrap;
        margin: 0 0 1rem;
        @media only screen and (min-width: 768px){
            overflow: hidden;
            flex-wrap: wrap;
            margin: 0;
        }
    }
}