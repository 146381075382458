.ludicrous-timeline{
    .slide-holder{
        border-radius: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
        padding: .625rem .3125rem;
        max-width: 1.5rem;
        box-sizing: border-box;
        margin: 0 auto;
        cursor: pointer;
        span{
            width: .625rem;
            height: .625rem;
            border: 1px solid #679CF6;
            background: #fff;
            display: block;
            border-radius: 50%;
            margin: 3px 0;
            overflow: hidden;
            &.featured{background: #679CF6;}
        }
    }
    .swiper-slide{
        &.swiper-slide-active{
            .slide-holder{
                background: linear-gradient(180deg, rgba(103, 156, 246, 0) 0%, #679CF6 100%);
            }
        }
    }
    .swiper-wrapper{
        align-items: flex-end;
    }
}