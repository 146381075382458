.user-card{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: .75rem;
    overflow: hidden;
    margin: 0 0 2.5rem;
    .details-area{
        background: #F6F9FF;
    }
    .image-holder{
        overflow: hidden;
        border-radius: 50%;
        width: 4.625rem;
        height: 4.625rem;
        flex: 0 0 4.625rem;
        margin: 0 1.125rem 0 0;
        img{
            display: block;
            width: 100%;
            height: 100%;
            margin: 0;
            object-fit: cover;
        }
    }
    .content-holder{
        font-size: 0.875rem;
        max-width: 65%;
        h2{
            font-size: 1.25rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-height: 1.6rem;
            overflow: hidden;
        }
        p{margin: 0;}
    }
    .btn-holder{
        .btn-primary{
            background: #679CF6;
            border-radius: .375rem;
            border: none;
            font-size: 0.875rem;
            display: block;
            width: 100%;
            padding: 0.4375rem;
        }
    }
}